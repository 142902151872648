import * as actions from "../actions/actionTypes";

const initialState = {
    chapters: [],
    loading: false,
    error: false,
};

const chapterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL chapters
        case actions.GET_ALL_CHAPTERS_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_ALL_CHAPTERS_SUCCESS:
            return { ...state, chapters: payload, loading: false };

        case actions.GET_ALL_CHAPTERS_FAIL:
            return { ...state, error: true, loading: false };

        default:
            return state;
    }
};

export default chapterReducer;
