import TabComponentsMiddleWare from "component/include/TabComponentsMiddleWare";
import { lazy } from "react";

const LearnScreen = lazy(() => import("screeen/LearnScreen"));
const SignUpScreen = lazy(() => import("screeen/SignUpScreen"));
const SignInScreen = lazy(() => import("screeen/SignInScreen"));
const AddNameEmail = lazy(() => import("component/include/AddNameEmail"));
const DivisionScreen = lazy(() => import("screeen/DivisionScreen"));
const StreamScreen = lazy(() => import("screeen/StreamScreen"));
const ProfileScreen = lazy(() => import("screeen/ProfileScreen"));
const AskScreen = lazy(() => import("screeen/AskScreen"));
const ResultsScreen = lazy(() => import("screeen/ResultsScreen"));
const QuizScreen = lazy(() => import("screeen/QuizScreen"));
const QuizList = lazy(() => import("component/include/QuizList"));
const ContactUsScreen = lazy(() => import("screeen/ContactUsScreen"));
const ContactSuccessScreen = lazy(() => import("screeen/ContactSuccessScreen"));
const DoubtAnswerScreen = lazy(() => import("screeen/DoubtAnswerScreen"));
const Notes = lazy(() => import("component/include/Notes"));
const ArtAndCrafts = lazy(() => import("component/include/ArtAndCrafts"));
const Physics = lazy(() => import("component/include/Physics"));
const VideoComponent = lazy(() => import("component/include/videoComponent"));
const InteractionPage = lazy(() => import("pages/interactionPage"));
const SettingsScreen = lazy(() => import("screeen/SettingsScreen"));
const PlanScreen = lazy(() => import("screeen/PlanScreen"));
const QuizResultScreen = lazy(() => import("screeen/QuizResultScreen"));
const Modify = lazy(() => import("component/include/Modify"));
const ThumbnailList = lazy(() => import("component/include/ThumbnailList"));
const InteractiveComponent = lazy(() =>
  import("component/include/InteractiveComponent")
);

const configureRoute = [
  {
    path: "/signup",
    element: <SignUpScreen />,
    private: false,
  },
  {
    path: "/signin",
    element: <SignInScreen />,
    private: false,
  },
  {
    path: "/",
    element: <LearnScreen />,
    private: true,
  },
  {
    path: "/result/:id",
    element: <QuizResultScreen />,
    private: true,
  },
  {
    path: "/personal-details",
    element: <AddNameEmail />,
    private: true,
  },
  {
    path: "/syllabus",
    element: <DivisionScreen />,
    private: true,
  },
  {
    path: "/streams",
    element: <StreamScreen />,
    private: true,
  },
  {
    path: "/learn",
    element: <LearnScreen />,
    private: true,
  },
  {
    path: "/ask",
    element: <AskScreen />,
    private: true,
  },
  {
    path: "/results",
    element: <ResultsScreen />,
    private: true,
  },
  {
    path: "/doubt/anwser",
    element: <DoubtAnswerScreen />,
    private: true,
  },
  {
    path: "/profile",
    element: <ProfileScreen />,
    private: true,
  },
  {
    path: "/contact-us",
    element: <ContactUsScreen />,
    private: true,
  },
  {
    path: "/contact-success",
    element: <ContactSuccessScreen />,
    private: true,
  },
  {
    path: "/settings",
    element: <SettingsScreen />,
    private: true,
  },
  {
    path: "/plan",
    element: <PlanScreen />,
    private: true,
  },
  {
    path: "/chapters/:subject",
    element: <Physics />,
    private: true,
  },
  {
    path: "/boards",
    element: <Modify />,
    private: true,
  },
  {
    path: "/chapter/notes",
    element: <Notes />,
    private: true,
  },
  {
    path: "/chapter/quiz",
    element: <QuizList />,
    private: true,
  },
  {
    path: "/chapter/art-crafts",
    element: <ArtAndCrafts />,
    private: true,
  },
  {
    path: "/chapter/videos",
    element: <ThumbnailList goto={"/chapter/video"} field={"lecture"} />,
    private: true,
  },
  {
    path: "/chapter/video",
    element: <VideoComponent />,
    private: true,
  },
  {
    path: "/chapter/interactives",
    element: (
      <ThumbnailList goto={"/chapter/interactive"} field={"experience"} />
    ),
    private: true,
  },
  {
    path: "/chapter/interactive",
    element: <InteractionPage />,
    private: true,
  },
  {
    path: "/chapter/worksheets",
    element: <ThumbnailList goto={"/chapter/worksheet"} field={"worksheet"} />,
    private: true,
  },
  {
    path: "/chapter/worksheet",
    element: (
      <InteractiveComponent goto={"/chapter/worksheet"} field={"worksheet"} />
    ),
    private: true,
  },
  {
    path: "/chapter/check",
    element: <TabComponentsMiddleWare />,
    private: true,
  },

  {
    path: "/questions/quiz/:id",
    element: <QuizScreen />,
    private: true,
  },
];

export default configureRoute;
