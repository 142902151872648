import * as actions from "../actions/actionTypes";

const initialState = {
    subjects: [],
    loading: false,
    error: false,
};

const subjectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL subjects
        case actions.GET_ALL_SUBJECTS_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_ALL_SUBJECTS_SUCCESS:
            return { ...state, subjects: payload, loading: false };

        case actions.GET_ALL_SUBJECTS_FAIL:
            return { ...state, error: true, loading: false };

        default:
            return state;
    }
};

export default subjectReducer;
