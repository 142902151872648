import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
    quizzes: [],
    error: false,
};

const quizzesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Get all Quiz
        case actions.GET_ALL_QUIZ_REQUEST:
            return { ...state, error: false };

        case actions.GET_ALL_QUIZ_SUCCESS:
            return { ...state, quizzes: payload };

        case actions.GET_ALL_QUIZ_FAIL:
            return { ...state, error: true };

        default:
            return state;
    }
};

export default quizzesReducer;
