import * as actions from "../actions/actionTypes";

const initialState = {
    plans: [],
    loading: false,
    error: false,
};

const planReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Plans
        case actions.GET_ALL_PLANS_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_ALL_PLANS_SUCCESS:
            return { ...state, plans: payload, loading: false };

        case actions.GET_ALL_PLANS_FAIL:
            return { ...state, error: true, loading: false };

        default:
            return state;
    }
};

export default planReducer;
