import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "redux/reducers/rootReducer";
let store = "";
// if (false) {
if (
  process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ||
  process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT"
) {
  store = createStore(rootReducer, compose(applyMiddleware(thunk)));
} else {
  store = createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
}

export default store;
