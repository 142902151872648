import ErrorBoundary from "component/include/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import RoutesData from "routes";

function loadScript(src) {
  const script = document.createElement("script");
  script.src = src;
  script.onload = () => {

  };
  script.onerror = () => {

  };
  document.body.appendChild(script);

}
function App() {
  loadScript("https://checkout.razorpay.com/v1/checkout.js");
  return (
    <ErrorBoundary>
      <div>
        <ToastContainer hideProgressBar />
        <RoutesData />
      </div>
    </ErrorBoundary>
  );
}

export default App;
