import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useState } from "react";
import { tabs } from "redux/actions/actionTypes";
import { getChapter } from "utils/fetchChapterFromLS";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function TabComponentsMiddleWare({ route }) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedChapter = location.state;
  const [chapter, setChapter] = useState(getChapter());
  useEffect(() => {
    console.log("selectedChapter : ", route);
    let elem = tabs
      .filter((tab) => !isEmpty(chapter[tab.key]))
      .map((tab, i) => tab);
    if (isEmpty(route)) {
      navigate(elem[0].href);
    }
  }, []);

  return <Loader />;
}
