import { FcGraduationCap } from "react-icons/fc";
import { BsFillChatTextFill } from "react-icons/bs";
import { SlFeed } from "react-icons/sl";
import { CgProfile } from "react-icons/cg";
import { AiOutlineSetting } from "react-icons/ai";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { GrValidate } from "react-icons/gr"
import Logo from "../images/ividya_adobe_express.svg";
import { Link, useLocation } from "react-router-dom";
import { VscGraph } from "react-icons/vsc";
import { useSelector } from "react-redux";

const navigation = [
  { name: "Learn", href: "/learn", icon: FcGraduationCap },
  { name: "Ask", href: "/ask", icon: BsFillChatTextFill },
  { name: "Results", href: "/results", icon: VscGraph },
  // { name: "My Reports", href: "#", icon: TbReportSearch, current: false },
  // { name: "Activity Feed", href: "/feed", icon: SlFeed },
  // { name: "Profile", href: "/profile", icon: CgProfile },
  { name: "Eshum Plus", href: "/plan", icon: GrValidate },
  {
    name: "Settings",
    href: "/settings",
    icon: AiOutlineSetting,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const { subscription } = useSelector(state => state.subscription)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden "
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="../images/ividya_adobe_express.svg"
                    alt="Workflow"
                  />
                </div> */}
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1 ">
                    {navigation.map((item) => (
                      <Link
                        onClick={() => setSidebarOpen(false)}
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.href === pathname
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.href === pathname
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                    <div className="text-gray-800 hover:text-gray-900 text-sm font-medium flex items-center justify-between border-t-2 py-4 px-1">
                      <span>Class {subscription?.klass?.name}</span>
                      <Link to="/boards" className="text-isham-500">CHANGE</Link>
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-8 w-auto" src={Logo} />
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.href === pathname
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md "
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.href === pathname
                          ? "text-red-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6 "
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
                <div className="text-gray-800 hover:text-gray-900 text-sm font-medium flex items-center justify-between border-t-2 py-2 px-1">
                  <span>Class {subscription?.klass?.name <= 12 ? subscription?.klass?.name : "12 +" }</span>
                  <Link to="/boards" className="text-isham-500">CHANGE</Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-1 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-isham-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6 mt-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
