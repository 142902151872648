import { combineReducers } from "redux";
import authReducer from "./authReducer";
import boardReducer from "./boardReducer";
import classReducer from "./classReducer";
import streamReducer from "./streamReducer";
import subscriptionReducer from "./subscriptionReducer";
import subjectReducer from "./subjectReducer";
import chapterReducer from "./chapterReducer";
import contactUsReducer from "./contactUsReducer";
import doubtReducer from "./doubtReducer";
import plansReducer from "./planReducer";
import quizzesReducer from "./quizReducer";
import resultsReducer from "./resultReducer";
import orderReducer from "./orderReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  board: boardReducer,
  class: classReducer,
  stream: streamReducer,
  subscription: subscriptionReducer,
  subject: subjectReducer,
  chapter: chapterReducer,
  contactUs: contactUsReducer,
  doubt: doubtReducer,
  plans: plansReducer,
  quizzes: quizzesReducer,
  results: resultsReducer,
  order: orderReducer,
})

export default rootReducer;