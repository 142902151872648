import * as actions from "../actions/actionTypes";

const subscriptionJSON = localStorage.getItem('subscription');
const subscription = subscriptionJSON ? JSON.parse(subscriptionJSON) : null;


const initialState = subscription ?
    {
        subscription: {
            user: subscription.user,
            board: subscription.board,
            klass: subscription.klass,
            stream: subscription.stream
        },
        loading: false,
        error: false
    }
    :
    {
        subscription: {
            user: "",
            board: "",
            klass: "",
            stream: ""
        },
        loading: false,
        error: false
    };

const subscriptionReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        // ADD OBJECT ID's TO SUBSCRIPTION
        case actions.ADD_USER_ID_TO_SUBSCRIPTION_SUCCESS:
            return { ...state, subscription: { ...state.subscription, user: payload } };
        case actions.ADD_BOARD_ID_TO_SUBSCRIPTION_SUCCESS:
            return { ...state, subscription: { ...state.subscription, board: payload } };
        case actions.ADD_CLASS_ID_TO_SUBSCRIPTION_SUCCESS:
            return { ...state, subscription: { ...state.subscription, klass: payload } };
        case actions.ADD_STREAM_ID_TO_SUBSCRIPTION_SUCCESS:
            return { ...state, subscription: { ...state.subscription, stream: payload } };

        // GET SUBSCRIPTION 
        case actions.GET_SUBSCRIPTION_REQUEST:
            return { ...state, loading: true, error: false };

        case actions.GET_SUBSCRIPTION_SUCCESS:
            return { ...state, loading: false, subscription: payload };

        case actions.GET_SUBSCRIPTION_FAIL:
            return { ...state, loading: false, error: true };
        default:
            return state;
    }
};

export default subscriptionReducer;
