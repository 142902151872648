import * as actions from "./actionTypes";
import axiosClient from "utils/axiosClient";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import * as ActionTypes from "./actionTypes";
import { json } from "react-router-dom";
import { SESSION_TOKEN, SESSION_USERINFO } from "utils/constants";

export const sendOTP = (payload) => async (dispatch) => {
  dispatch({
    type: actions.OTP_SEND_REQUEST,
  });

  let url = `${process.env.REACT_APP_BASE_URL}api/auth/login`;
  try {
    const res = await axiosClient.post(url, payload.formData, {
      headers: {
        token: ActionTypes.BASE_TOKEN,
      },
    });
    dispatch({
      type: actions.OTP_SEND_SUCCESS,
      payload: res.data.isSubscribed,
    });
    payload.callback();
  } catch (error) {
    dispatch({
      type: actions.OTP_SEND_FAIL,
    });
    toast.error("something went wrong!");
  }
};

export const verifyOTP = (payload) => async (dispatch) => {
  dispatch({
    type: actions.OTP_VERIFY_REQUEST,
  });

  let url = `${process.env.REACT_APP_BASE_URL}api/auth/otp-verify`;
  try {
    const res = await axiosClient.post(url, payload.formData, {
      headers: {
        token: ActionTypes.BASE_TOKEN,
      },
    });
    if (res.status === 200) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      Cookies.set(SESSION_TOKEN, res.data.token, { expires: expirationDate });
      Cookies.set(SESSION_USERINFO, JSON.stringify(res.data.data), {
        expires: expirationDate,
      });
      dispatch({
        type: actions.OTP_VERIFY_SUCCESS,
        payload: res.data,
      });
      payload.callback();
    } else toast.error("WRONG OTP!");
  } catch (error) {
    dispatch({
      type: actions.OTP_VERIFY_FAIL,
    });
    toast.error("something went wrong!");
  }
};

export const logout = (payload) => (dispatch) => {
  Cookies.remove(SESSION_TOKEN);
  Cookies.remove(SESSION_USERINFO);
  dispatch({ type: actions.LOGOUT });
  payload.callback();
  setTimeout(() => {
    toast.success("You have successfully logged out!");
  }, 500);
};

export const addUserDetails =
  ({ formData, id, callback }) =>
  async (dispatch) => {
    dispatch({
      type: actions.ADD_USER_DETAILS_REQUEST,
    });
    let resp = {};

    try {
      resp = await axiosClient.patch(
        `${process.env.REACT_APP_BASE_URL}api/user/${id}`,
        formData
      );
      dispatch({
        type: actions.ADD_USER_DETAILS_SUCCESS,
        payload: resp.data.data,
      });
      toast.success("User Details Updated Successfully !");
      Cookies.set(SESSION_USERINFO, JSON.stringify(resp.data.data));
      callback();
    } catch (error) {
      dispatch({
        type: actions.ADD_USER_DETAILS_FAIL,
      });
      if (error.response.data.message === "Phone number already exists!")
        toast.error("Phone number already exists!");

      if (error.response.data.message === "Email already exists!")
        toast.error("Email already exists!");
    }
  };
