import * as actions from "../actions/actionTypes";

const orderJSON = localStorage.getItem('order');
const order = orderJSON ? JSON.parse(orderJSON) : null;

const initialState = order ?
    {
        order,
        loading: false,
        error: false,
    }
    : 
    {
        order: {},
        loading: false,
        error: false,
    }

const orderReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Plans
        case actions.GET_USER_ORDER_BY_STREAM_ID_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_USER_ORDER_BY_STREAM_ID_SUCCESS:
            return { ...state, order: payload, loading: false };

        case actions.GET_USER_ORDER_BY_STREAM_ID_FAIL:
            return { ...state, error: true, loading: false };

        default:
            return state;
    }
};

export default orderReducer;
