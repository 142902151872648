import Cookies from "js-cookie";
import { SESSION_TOKEN } from "./constants";
import axios from "axios";

const token = Cookies.get(SESSION_TOKEN);
console.log({ token });
const axiosClient = axios.create({
  baseURL: "", // Replace with your API base URL
  //   timeout: 5000, // Adjust the timeout as needed
  headers: {
    "Content-Type": "multipart/form-data",
    token,
  },
});

export default axiosClient;
