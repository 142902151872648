import * as actions from "../actions/actionTypes";

const initialState = {
    loading: false,
};

const contactUsReducer = (state = initialState, { type }) => {
    switch (type) {
        // Add Contact Us
        case actions.ADD_CONTACT_US_REQUEST:
            return { loading: true };

        case actions.ADD_CONTACT_US_SUCCESS:
            return { loading: false };

        case actions.ADD_CONTACT_US_FAIL:
            return { loading: false };

        default:
            return state;
    }
};

export default contactUsReducer;
