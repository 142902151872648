import * as actions from "../actions/actionTypes";

const initialState = {
    results: [],
    result: {},
    quiz: [],
    progress: [],
    loading: false,
    error: false,
};

const resultReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Results
        case actions.GET_ALL_RESULTS_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_ALL_RESULTS_SUCCESS:
            return { ...state, results: payload.data, loading: false };

        case actions.GET_ALL_RESULTS_FAIL:
            return { ...state, error: true, loading: false };

        // GET One Result By Id
        case actions.GET_RESULT_BY_ID_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_RESULT_BY_ID_SUCCESS:
            return { ...state, result: payload.data, loading: false };

        case actions.GET_RESULT_BY_ID_FAIL:
            return { ...state, error: true, loading: false };

        default:
            return state;
    }
};

export default resultReducer;
