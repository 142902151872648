import * as actions from "../actions/actionTypes";

const initialState = {
    streams: [],
    loading: false,
    error: false,
};

const streamReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Streams
        case actions.GET_ALL_STREAMS_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_ALL_STREAMS_SUCCESS:
            return { ...state, streams: payload, loading: false };

        case actions.GET_ALL_STREAMS_FAIL:
            return { ...state, error: true, loading: false };

        default:
            return state;
    }
};

export default streamReducer;
