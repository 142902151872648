import Cookies from "js-cookie";
import * as actions from "../actions/actionTypes";
import { SESSION_USERINFO } from "utils/constants";

const userCookie = Cookies.get(SESSION_USERINFO);
const user = userCookie ? JSON.parse(userCookie) : null;

const initialState = user
  ? { isLoggedIn: true, user, loading: false, isSubscribed: false }
  : { isLoggedIn: false, user: null, loading: false, isSubscribed: false };

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // OTP LOGIN user
    case actions.OTP_SEND_REQUEST:
      return { ...state, loading: true };
    case actions.OTP_SEND_SUCCESS:
      return { ...state, loading: false, isSubscribed: payload };
    case actions.OTP_SEND_FAIL:
      return { ...state, loading: false };

    case actions.OTP_VERIFY_REQUEST:
      return { ...state };
    case actions.OTP_VERIFY_SUCCESS:
      return { ...state, isLoggedIn: true, user: payload.data };
    case actions.OTP_VERIFY_FAIL:
      return { ...state };
    case actions.LOGOUT:
      return { ...state, isLoggedIn: false, user: null };

    // Google LOGIN user
    case actions.USER_LOGIN_REQUEST:
      return { ...state, loading: true };
    case actions.USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.data,
        loading: false,
        isSubscribed: payload.isSubscribed,
      };
    case actions.USER_LOGIN_FAIL:
      return { ...state, loading: false };

    // Update User Details
    case actions.ADD_USER_DETAILS_REQUEST:
      return { ...state };

    case actions.ADD_USER_DETAILS_SUCCESS:
      return { ...state, user: payload };

    case actions.ADD_USER_DETAILS_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export default authReducer;
