import React, { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  const history = useNavigate();
  const handleReload = () => {
    history("/learn");
    resetErrorBoundary();
  };

  return (
    <div className="bg-red-50 p-8 rounded-md flex items-center justify-center">
      <FiAlertCircle className="text-red-500 mr-2" />
      <div>
        <h2 className="text-lg font-medium text-red-500">
          Something went wrong.
        </h2>
        <p className="text-gray-500 mt-2">{error && error.toString()}</p>
        <button
          onClick={handleReload}
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Reload Application
        </button>
      </div>
    </div>
  );
}

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  const resetErrorBoundary = () => {
    window.location.reload();
  };

  const handleError = (error) => {
    console.error(error);
    setHasError(true);
  };

  return hasError ? (
    <ErrorBoundaryFallback
      error={hasError}
      resetErrorBoundary={resetErrorBoundary}
    />
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
}

export default ErrorBoundary;
