import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";
import { SESSION_TOKEN, SESSION_USERINFO } from "utils/constants";

const PrivateRoute = ({ children }) => {
  const auth = Cookies.get(SESSION_TOKEN);
  return auth ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoute;
