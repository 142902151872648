import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import configureRoute from "./routes";
import HomeScreen from "../screeen/HomeScreen";
import Loader from "component/include/Loader";
import Navbar from "component/include/Navbar";
import axiosClient from "utils/axiosClient";

const RoutesData = () => {
  const { pathname } = useLocation();

  const showSidebarNavbar = () => {
    switch (pathname) {
      case "/signin":
        return false;
      case "/signup":
        return false;
      case "/plan":
        return false;
      case "/personal-details":
        return false;
      case "/boards":
        return false;
      case "/syllabus":
        return false;
      case "/streams":
        return false;

      default:
        return true;
    }
  };

  return (
    <>
      <section className="md:flex md:justify-center overflow-hidden text-slate-800 bg-white">
        <div className={`${showSidebarNavbar() ? "md:w-64" : "md:w-0"}`}>
          {/* <HomeScreen responsive={responsive} setResponsive={setResponsive} /> */}
          {showSidebarNavbar() && <HomeScreen />}
        </div>
        <div className={`overflow-y-auto w-full`}>
          {showSidebarNavbar() && <Navbar />}
          <Suspense fallback={<Loader />}>
            <Routes>
              {configureRoute.map((item, key) =>
                item.private ? (
                  <Route key={item.path} exact element={<PrivateRoute />}>
                    <Route key={key} path={item.path} element={item.element} />
                  </Route>
                ) : (
                  <Route key={key} path={item.path} element={item.element} />
                )
              )}
            </Routes>
          </Suspense>
        </div>
      </section>
    </>
  );
};

export default RoutesData;
