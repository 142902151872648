import Cookies from "js-cookie";
import { SESSION_TOKEN } from "utils/constants";

export const OTP_SEND_REQUEST = "OTP_SEND_REQUEST";
export const OTP_SEND_SUCCESS = "OTP_SEND_SUCCESS";
export const OTP_SEND_FAIL = "OTP_SEND_FAIL";

export const OTP_VERIFY_REQUEST = "OTP_VERIFY_REQUEST";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAIL = "OTP_VERIFY_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const BASE_TOKEN = Cookies.get(SESSION_TOKEN);

export const GET_ALL_BOARDS_REQUEST = "GET_ALL_BOARDS_REQUEST";
export const GET_ALL_BOARDS_SUCCESS = "GET_ALL_BOARDS_SUCCESS";
export const GET_ALL_BOARDS_FAIL = "GET_ALL_BOARDS_FAIL";

export const GET_ALL_CLASSES_REQUEST = "GET_ALL_CLASSES_REQUEST";
export const GET_ALL_CLASSES_SUCCESS = "GET_ALL_CLASSES_SUCCESS";
export const GET_ALL_CLASSES_FAIL = "GET_ALL_CLASSES_FAIL";

export const GET_ALL_STREAMS_REQUEST = "GET_ALL_STREAMS_REQUEST";
export const GET_ALL_STREAMS_SUCCESS = "GET_ALL_STREAMS_SUCCESS";
export const GET_ALL_STREAMS_FAIL = "GET_ALL_STREAMS_FAIL";

export const GET_ALL_SUBJECTS_REQUEST = "GET_ALL_SUBJECTS_REQUEST";
export const GET_ALL_SUBJECTS_SUCCESS = "GET_ALL_SUBJECTS_SUCCESS";
export const GET_ALL_SUBJECTS_FAIL = "GET_ALL_SUBJECTS_FAIL";

export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL";

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const ADD_USER_ID_TO_SUBSCRIPTION_SUCCESS =
  "ADD_USER_ID_TO_SUBSCRIPTION_SUCCESS";
export const ADD_BOARD_ID_TO_SUBSCRIPTION_SUCCESS =
  "ADD_BOARD_ID_TO_SUBSCRIPTION_SUCCESS";
export const ADD_CLASS_ID_TO_SUBSCRIPTION_SUCCESS =
  "ADD_CLASS_ID_TO_SUBSCRIPTION_SUCCESS";
export const ADD_STREAM_ID_TO_SUBSCRIPTION_SUCCESS =
  "ADD_STREAM_ID_TO_SUBSCRIPTION_SUCCESS";

export const GET_ALL_CHAPTERS_REQUEST = "GET_ALL_CHAPTERS_REQUEST";
export const GET_ALL_CHAPTERS_SUCCESS = "GET_ALL_CHAPTERS_SUCCESS";
export const GET_ALL_CHAPTERS_FAIL = "GET_ALL_CHAPTERS_FAIL";

export const ADD_USER_DETAILS_REQUEST = "ADD_USER_DETAILS_REQUEST";
export const ADD_USER_DETAILS_SUCCESS = "ADD_USER_DETAILS_SUCCESS";
export const ADD_USER_DETAILS_FAIL = "ADD_USER_DETAILS_FAIL";

export const ADD_CONTACT_US_REQUEST = "ADD_CONTACT_US_REQUEST";
export const ADD_CONTACT_US_SUCCESS = "ADD_CONTACT_US_SUCCESS";
export const ADD_CONTACT_US_FAIL = "ADD_CONTACT_US_FAIL";

export const USER_LOG_IN = "USER_LOG_IN";
export const USER_LOG_IN_SUCCESS = "USER_LOG_IN_SUCCESS";
export const USER_LOG_IN_FAIL = "USER_LOG_IN_FAIL";

export const GET_ALL_DOUBTS_REQUEST = "GET_ALL_DOUBTS_REQUEST";
export const GET_ALL_DOUBTS_SUCCESS = "GET_ALL_DOUBTS_SUCCESS";
export const GET_ALL_DOUBTS_FAIL = "GET_ALL_DOUBTS_FAIL";

export const ADD_DOUBT_REQUEST = "ADD_DOUBT_REQUEST";
export const ADD_DOUBT_SUCCESS = "ADD_DOUBT_SUCCESS";
export const ADD_DOUBT_FAIL = "ADD_DOUBT_FAIL";

export const GET_ALL_PLANS_REQUEST = "GET_ALL_PLANS_REQUEST";
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS";
export const GET_ALL_PLANS_FAIL = "GET_ALL_PLANS_FAIL";

export const GET_ALL_QUIZ_REQUEST = "GET_ALL_QUIZ_REQUEST";
export const GET_ALL_QUIZ_SUCCESS = "GET_ALL_QUIZ_SUCCESS";
export const GET_ALL_QUIZ_FAIL = "GET_ALL_QUIZ_FAIL";

export const ADD_QUIZ_PROGRESS_REQUEST = "ADD_QUIZ_PROGRESS_REQUEST";
export const ADD_QUIZ_PROGRESS_SUCCESS = "ADD_QUIZ_PROGRESS_SUCCESS";
export const ADD_QUIZ_PROGRESS_FAIL = "ADD_QUIZ_PROGRESS_FAIL";

export const GET_ALL_RESULTS_REQUEST = "GET_ALL_RESULTS_REQUEST";
export const GET_ALL_RESULTS_SUCCESS = "GET_ALL_RESULTS_SUCCESS";
export const GET_ALL_RESULTS_FAIL = "GET_ALL_RESULTS_FAIL";

export const GET_RESULT_BY_ID_REQUEST = "GET_RESULT_BY_ID_REQUEST";
export const GET_RESULT_BY_ID_SUCCESS = "GET_RESULT_BY_ID_SUCCESS";
export const GET_RESULT_BY_ID_FAIL = "GET_RESULT_BY_ID_FAIL";

export const GET_USER_ORDER_BY_STREAM_ID_REQUEST =
  "GET_USER_ORDER_BY_STREAM_ID_REQUEST";
export const GET_USER_ORDER_BY_STREAM_ID_SUCCESS =
  "GET_USER_ORDER_BY_STREAM_ID_SUCCESS";
export const GET_USER_ORDER_BY_STREAM_ID_FAIL =
  "GET_USER_ORDER_BY_STREAM_ID_FAIL";

export const tabs = [
  { name: "Videos", href: "/chapter/videos", key: "lecture" },
  { name: "Interactives", href: "/chapter/interactives", key: "experience" },
  { name: "Notes", href: "/chapter/notes", key: "notes" },
  {
    name: "Art&Crafts",
    href: "/chapter/art-crafts",
    key: "art_craft",
  },
  { name: "Worksheets", href: "/chapter/worksheets", key: "worksheet" },
  { name: "Quiz", href: "/chapter/quiz", key: "quiz" },
];
