import * as actions from "../actions/actionTypes";
import produce from "immer"

const initialState = {
    doubts: [],
    loading: false,
    error: false,
};

const doubtReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // GET ALL Doubts
        case actions.GET_ALL_DOUBTS_REQUEST:
            return { ...state, error: false, loading: true };

        case actions.GET_ALL_DOUBTS_SUCCESS:
            return { ...state, doubts: payload, loading: false };

        case actions.GET_ALL_DOUBTS_FAIL:
            return { ...state, error: true, loading: false };

        case actions.ADD_DOUBT_REQUEST:
            return { ...state, loading: true };

        case actions.ADD_DOUBT_SUCCESS:
            const newState = produce(state, draftState => {
                draftState.doubts.unshift(payload);
                draftState.loading = false;
            })
            return newState;

        case actions.ADD_DOUBT_FAIL:
            return { ...state, loading: false };

        default:
            return state;
    }
};

export default doubtReducer;
